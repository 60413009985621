<template>
  <div class="example">
    <apex-chart
      ref="chart"
      :type="chartType"
      :height="height"
      :width="width"
      :options="chartOptions"
      :series="series"
    ></apex-chart>
  </div>
</template>
  
  <script>
import Vue from "vue";
import VueApexCharts from "vue-apexcharts";
Vue.use(VueApexCharts);
export default {
  components: {
    "apex-chart": VueApexCharts,
  },
  props: {
    isDetail: {
      type: Boolean,
      default: true,
    },
    toolBarShow: {
      type: Boolean,
      default: false,
    },
    zoomEnable: {
      type: Boolean,
      default: true,
    },
    titleName: {
      type: String,
      default: "",
    },
    xAxisName: {
      type: String,
      default: "",
    },
    yAxisName: {
      type: String,
      default: "",
    },
    isStacked: {
      type: Boolean,
      default: true,
    },
    isHorizontal: {
      type: Boolean,
      default: false,
    },
    stackType: {
      type: String,
      default: "normal",
    },
    height: {
      default: "auto",
    },
    width: {
      default: "100%",
    },
    series: {
      type: Array,
      default() {
        return [];
      },
    },
    categories: {
      type: Array,
      default() {
        return [];
      },
    },
    dataType: {
      type: String,
      default: "category",
    },
    chartType: {
      type: String,
      default: "bar",
    },
  },
  data() {
    return {
      chartOptions: {},
    };
  },
  methods: {
    setChartConfig() {
      this.chartOptions = {
        chart: {
          toolbar: {
            show: false,
          },
          height: 350,
          type: "bar",
          stacked: this.isStacked,
          stackType: this.stackType,
          zoom: {
            enabled: true,
          },
          animations: {
            enabled: false,
            speed: 500,
          },
        },
        //15 colors
        colors: [
          "#ef476f",
          "#ffd166",
          "#06d6a0",
          "#118ab2",
          "#073b4c",
          "#ff6b35",
          "#f7c59f",
          "#efefd0",
          "#004e89",
          "#1a659e",
          "#780000",
          "#c1121f",
          "#669bbc",
          "#788bff",
          "#9bb1ff",
        ],
        // theme: {
        //       mode: 'light',
        //       palette: 'palette2',
        //       // monochrome: {
        //       //     enabled: true,
        //       //     color: '#255aee',
        //       //     shadeTo: 'light',
        //       //     shadeIntensity: 0.5
        //       // },
        //   },
        plotOptions: {
          bar: {
            horizontal: this.isHorizontal,
            columnWidth: "50%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        title: {
          text: "",
          align: "left",
        },
        grid: {
          show: false,
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
        },
        xaxis: {
          type: this.dataType,
          categories: this.categories,
          title: {
            text: this.xAxisName,
            offsetY: 40,
          },
        },
        yaxis: {
          type: "numeric",
          title: {
            text: this.yAxisName,
          },
          labels: {
            formatter: function (value) {
              if (typeof value === "number") {
                if (Math.abs(value) >= 1e10) {
                  // Convert to scientific notation if value exceeds 6 digits
                  return value.toExponential(1);
                }
                return value.toFixed(0);
              } else {
                return value.toFixed(0)+"%";
              }
            },
          },
        },
        fill: {
          opacity: 1,
        },
        markers: {
          size: 6,
        },
        stroke: {
          show: true,
          curve: "smooth",
          colors: undefined,
          width: 2,
          dashArray: 0,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      };
    },
    changeStyle(isDetail, toolBarShow, zoomEnable) {
      this.chartOptions.chart.toolbar.show = toolBarShow;
      this.chartOptions.chart.zoom.enabled = zoomEnable;
      this.chartOptions.xaxis.show = isDetail;
      this.chartOptions.yaxis.show = isDetail;
      this.chartOptions.grid.show = isDetail;
      if (isDetail == false) {
        this.chartOptions.xaxis.title.text = "";
        this.chartOptions.title.text = "";
        this.chartOptions.grid.row.colors = undefined;
        this.chartOptions.grid.column.colors = undefined;
      }
    },
    setChartInfo(titleName, xAxisName, yAxisName) {
      this.chartOptions.title.text = titleName;
      this.chartOptions.xaxis.title.text = xAxisName;
      this.chartOptions.yaxis.title.text = yAxisName;
    },

    setXCategory(categories, dateType) {
      this.chartOptions.xaxis.type = dateType;
      this.chartOptions.xaxis.categories = categories;
    },

    addSeries(name, data) {
      this.$refs.chart.appendSeries({
        name: name,
        data: data,
      });
    },
    resetSeries() {
      this.$refs.chart.resetSeries();
    },
  },
  mounted() {},
  created() {
    this.setChartConfig();
    this.setChartInfo(this.titleName, this.xAxisName, this.yAxisName);
    this.changeStyle(this.isDetail, this.toolBarShow, this.zoomEnable);
    this.setXCategory(this.categories, this.dateType);
  },
};
</script>